import CardButton from '../../design-system/button/customButtons/CardButton';
import DataContainer from '../../design-system/container/customContainers/DataContainer';
import PrimaryTable from '../../design-system/table/customTables/PrimaryTable';


export const parseDate = (date) => {
    if (date)
        return new Date(date).toLocaleDateString('en-US')
}

export const parseLifecycle = (lifecycle) => {
    if (lifecycle)
        return {
            firstFactored: parseDate(lifecycle?.firstFactored),
            lastFactored:parseDate(lifecycle?.lastFactored),
            kybKycSubmitted: parseDate(lifecycle?.kybKycSubmitted),
            plaidConnected: parseDate(lifecycle?.plaidConnected),
            codatConnected: parseDate(lifecycle?.codatConnected)
        };
};

export const parseOrganization = (organization) => ({
    ...organization.organization,
    name: organization?.organization?.orgName || organization?.organization?.name,
    lifecycle: parseLifecycle(organization?.organization?.lifecycle),
    invoices: organization.invoices,
    jsonData: organization.organization,
});

export const organizationPageInformation = (organization) => ({
    id: { title: 'Organization ID', value: organization?.id },
    name: {
        title: 'Name',
        value: organization?.name,
    },
    lifecycle: {
        title: 'Lifecycle',
        value: organization?.lifecycle && (
            <>
                <p>First Factored: {organization?.lifecycle?.firstFactored}</p>
                <p>Last Factored: {organization?.lifecycle?.lastFactored}</p>
                <p>KYB/KYC Submitted: {organization?.lifecycle?.kybKycSubmitted}</p>
                <p>Plaid Connected: {organization?.lifecycle?.plaidConnected}</p>
                <p>Codat Connected: {organization?.lifecycle?.codatConnected}</p>
            </>
        ),
    },
});

export const organizationPageInvoices = (invoices) => {
    const data = {
        columns: ['ID', 'Customer', 'Amount'],
        rows: invoices?.map((invoice) => (
            <tr key={invoice.id}>
                <td className='scroll'>{invoice.id}</td>
                <td className='scroll'>{invoice.fields.payor || invoice.fields.billed_by}</td>
                <td>{invoice.fields.amount && `$${invoice.fields.amount}`}</td>
            </tr>
        ))
    }

    if (invoices?.length)
        return (
            <PrimaryTable data={data} props={{ 'data-testid': 'org-invoices-table '}} />
        );

    return (
        <p><strong>No invoices to show.</strong></p>
    );
};

export const organizationPageJson = (json) => ({
    json: {
        title: 'JSON',
        value: (
            <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(json, null, 4)}
            </pre>
        ),
    },
});

export const getOrganizationData = (active, organization) => {
    switch (active) {
    case 'Information':
        return <DataContainer props={{ data: organizationPageInformation(organization), 'data-testid': 'org-info-table' }} />;
    case 'Invoices':
        return organizationPageInvoices(organization?.invoices);
    case 'JSON':
        return <DataContainer props={{ data: organizationPageJson(organization.jsonData), 'data-testid': 'org-json-table' }} />;
    default:
        return <DataContainer props={{ data: organizationPageInformation(organization), 'data-testid': 'org-info-table' }} />;
    }
};

export const getPageElementsForOrg = (props) => {
    return props.showElements && (
        <div style={{ width: '730px' }}>
            <div
                style={{
                    margin: '1rem 0',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '730px',
                }}>
                <CardButton
                    icon={<h2>?</h2>}
                    text='Information'
                    active={props.active}
                    textDesc='View organization information'
                    onClick={() => props.setActive('Information')}
                />
                <CardButton
                    data-testid="org-page-invoices-tab"
                    icon={<i className='fa-solid fa-file-invoice-dollar fa-2x' />}
                    text='Invoices'
                    active={props.active}
                    textDesc={"View organization's invoices"}
                    onClick={() => props.setActive('Invoices')}
                />
                <CardButton
                    icon={<h2>{'{ }'}</h2>}
                    text='JSON'
                    active={props.active}
                    textDesc='View full raw object'
                    onClick={() => props.setActive('JSON')}
                />
            </div>
            <div>{getOrganizationData(props.active, props.organization)}</div>
        </div>
    )
}
