export const fetchPurchaseOrder = async (executeFetch, setPurchaseOrder) => {
    await executeFetch().then((response) => {
        if (response.success) {
            setPurchaseOrder(response.body);
        }
    });
};

export const getPurchaseOrderData = (purchaseorder) => {
    console.log('po', purchaseorder);
    if (!purchaseorder) {
        return {
            columns: [ 'Purchase Order' ],
            rows: [],
        }
    }

    const MAX_WIDTH = 150;
    const rows = Object.keys(purchaseorder).map(key => 
        <tr key={key}>
            <td style={{ maxWidth: MAX_WIDTH }}>{key}</td><td>{purchaseorder[key]}</td>
        </tr>
    )
    return {
        columns: [ 'Purchase Order' ],
        rows: rows,
    }
};
