import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fetchWebhook } from './helper';
import Page from '../components/Page';
import useFetch from '../../hooks/useFetch';
import routes from '../../utils';
import { METHODS, WEBHOOK_API_URL, APPLICATION_JSON } from '../../constants';
import LoadingSpinner from '../../components/LoadingSpinner';
import PrimaryButton from '../../design-system/button/customButtons/PrimaryButton';
import { useCurrentUser } from '../../hooks/context/useCurrentUser';

const Form = styled.form({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    maxWidth: '400px',
    fontSize: '14px',
    fontFamily: 'Outfit, sans-serif',
});
const Input = styled.input({});
const TextArea = styled.textarea({});
const ConstraintMessage = styled.span({
    color: 'red',
});

export default function WebhookNewPage() {
    const [ webhook, setWebhook ] = useState();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }} = useForm();
    const { userEmail } = useCurrentUser();
    const config = {
        method: METHODS.POST,
        headers: { 
            'Content-Type': APPLICATION_JSON,
        },
    };

    const { executeFetch, error, isLoading } = useFetch(`${WEBHOOK_API_URL}`, config);    

    const redirect = (id) => {
        navigate(`${routes.WEBHOOK.path}/${id}`);
        return;
    }

    useEffect(() => {
        if(!webhook?.id) return
        redirect(webhook?.id);
    }, [webhook?.id])

    const onSubmit = async (data) => {
        config.body = JSON.stringify(data);
        await fetchWebhook(executeFetch, setWebhook);
    }

    return (
        <Page>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <label>Event {errors?.event?.message && <ConstraintMessage>({errors?.event?.message})</ConstraintMessage>}</label> 
                    <div>
                        <input type='Checkbox' {...register('event', {
                            required: {
                                value: true,
                                message: 'required minimum of one event',
                            },                         
                        })} value='PurchaseOrder.Created'/> PurchaseOrder.Created
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='PurchaseOrder.VendorCreated'/> PurchaseOrder.VendorCreated
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='ProvisionalInvoice.Submitted'/> ProvisionalInvoice.Submitted
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='MarketplaceOrganization.Created'/> MarketplaceOrganization.Created
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='ChannelPartner.VendorOnboarded'/> ChannelPartner.VendorOnboarded
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Invoice.Approved'/> Invoice.Approved
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Order.Created'/> Order.Created
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Order.Fulfilled'/> Order.Fulfilled
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Order.Processing'/> Order.Processing
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Marketing.GetPaidNowEligibleInvoice'/> Marketing.GetPaidNowEligibleInvoice
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Marketing.PaymentOrderSent'/> Marketing.PaymentOrderSent
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Operations.UserAuthentication'/> Operations.UserAuthentication
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Business.Created'/> (Compliance) Business.Created
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Business.Updated'/> (Compliance) Business.Updated
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='User.Login'/> User.Login
                    </div>
                    <div>
                        <input type='Checkbox' {...register('event')} value='Operations.General'/> Operations.General
                    </div>

                    <label>Type {errors?.notificationType?.message && <ConstraintMessage>({errors?.notificationType?.message})</ConstraintMessage>}</label> 
                    <select {...register('notificationType', {
                        required: {
                            value: true,
                            message: 'required minimum of one notificationType',
                        },                         
                    })}>
                        <option value='webhook'>Webhook</option>
                        <option value='sqs'>SQS</option>
                    </select>

                    <label>Endpoint {errors?.endpoint?.message && <ConstraintMessage>({errors?.endpoint?.message})</ConstraintMessage>}</label> 
                    <Input {...register('endpoint', {
                        required: {
                            value: true,
                            message: 'required',
                        }, 
                        pattern: {
                            value: /http.*:\/\/.*/i,
                            message: 'must be a valid url'
                        },
                    })} placeholder={'url for the webhook or sqs queue'} />

                    <label>Created For {errors?.createdFor?.message && <ConstraintMessage>({errors?.createdFor?.message})</ConstraintMessage>}</label> 

                    <select {...register('createdFor', {
                        required: {
                            value: true,
                            message: 'required minimum of one',
                        },                         
                    })}>
                        <option value='ANY'>Lunch Ops</option>
                        <option value='SchoolsOpen'>SchoolsOpen</option>
                    </select>

                    <label>Description {errors?.description?.message && <ConstraintMessage>({errors?.description?.message})</ConstraintMessage>}</label> 
                    <TextArea {...register('description', {
                        required: {
                            value: true,
                            message: 'required'
                        },
                    })} placeholder={'describe the purpose of the notification'} />


                    <Input {...register('createdBy', { required: true })} value={userEmail} readOnly hidden />
                    
                    

                    <PrimaryButton
                        text="Create new webhook"
                    />
                </Form>)}
            {error && (
                <div data-testid="webhooks-create-table-error-message" style={{ padding: '2rem', textAlign: 'center' }}>
                    Error has occurred while saving the webhook definition. Please try again.
                </div>
            )}
        </Page>
    );
}
