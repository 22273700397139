import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import { useCurrentUser } from '../hooks/context/useCurrentUser';
import routes from '../utils';

const Container = styled.div({
    padding: '0.5rem 2rem',
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px #E3E9F0 solid',
});

const MenuGroup = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const MenuItem = styled.button({
    border: 'none',
    color: '#25C2BC',
    background: 'none',
    margin: '0 0.25rem',
    '&:hover': {
        color: '#25C2BC',
        backgroundColor: 'rgb(140 197 184 / 22%)',
    },
    '&.active': {
        color: '#25C2BC',
        backgroundColor: 'rgb(140 197 184 / 22%)',
    },
});

const MenuItemLogOutButton = styled.button({
    border: 'none',
    backgroundColor: '#25C2BC',
    color: '#fff',
    '&:hover': {
        transform: 'scale(1.1)',
    },
});

const MenuItemTitle = styled.h4({
    margin: '1rem',
});

export default function NavigationBar() {
    const [active, setActive] = useState('Organization');
    const { setIsLoggedIn, setUserEmail } = useCurrentUser();
    const navigate = useNavigate();
    const urlPath = window.location.pathname;

    const handleSignOut = () => {
        navigate('/');
        sessionStorage.clear();
        localStorage.clear();
        setIsLoggedIn(false);
        setUserEmail();
    };

    const handleClick = (route) => {
        navigate(route.path);
        setActive(route.path);
    }

    useEffect(() => {
        setActive(urlPath);
    }, [])
    

    const renderNavigationMenu = (route) => {
        if (route.navbar) {
            const isActive = active === route.path ? 'active' : '';
            return (
                <MenuItem key={route.title} onClick={() => handleClick(route)} className={isActive}>
                    <MenuItemTitle>{route.title}</MenuItemTitle>
                </MenuItem>
            );
        } return null;
    };

    return (
        <Container>
            <MenuGroup>
                <img src={logo} alt='lunch_logo' height='40px' />
                <h2 style={{ margin: '1rem' }}>Lunch Ops</h2>
            </MenuGroup>
            <MenuGroup>{Object.values(routes).map((route) => renderNavigationMenu(route))}</MenuGroup>
            <MenuGroup>
                <MenuItemLogOutButton onClick={handleSignOut}>
                    <h4 style={{ margin: '0.75rem' }}>Logout</h4>
                </MenuItemLogOutButton>
            </MenuGroup>
        </Container>
    );
}
