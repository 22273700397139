import { createContext, useContext, useEffect, useState } from 'react';

const CurrentUserContext = createContext();

export default function CurrentUserProvider({ children, testValues = null }) {
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [userEmail, setUserEmail] = useState();
    const email = localStorage.getItem('userEmail');
    const auth = sessionStorage.getItem('auth');

    useEffect(() => {
        setUserEmail(JSON.parse(email));
    }, [email])

    useEffect(() => {
        setIsLoggedIn(!!auth);
        sessionStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
    }, [isLoggedIn, auth]);

    return (
        <CurrentUserContext.Provider
            value={testValues ?? { 
                isLoggedIn,
                setIsLoggedIn,
                userEmail,
                setUserEmail
            }}>
            {children}
        </CurrentUserContext.Provider>
    );
}

export const useCurrentUser = () => useContext(CurrentUserContext);
