import { useCallback, useState } from 'react';


export default function useFetch(url, options) {
    //console.log(url, options);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    if (!options.headers) {
        options.headers = {};
    }
    options.headers['Content-Type'] = 'application/json';

    const auth = sessionStorage.getItem('auth');
    if (auth) {
        const idToken = JSON.parse(sessionStorage.getItem('auth')).idToken;
        options.headers.Authorization = idToken;    
    }

    const parseResponse = async (res) => {
        const json = await res.json();
        //console.log(`res.status: ${res.status}`, JSON.stringify(json));
        return {
            statusCode: res.status,
            body: json,
            statusText: res.statusText,
            success: res.ok,
        };
    };

    const executeFetch = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        return await fetch(url, options)
            .then((res) => parseResponse(res))
            .then((response) => {
                if (!response.success) {
                    setError(response);
                    return response;
                }
                setData(response);
                return response;
            })
            .finally(() => setIsLoading(false));
    }, [url, options]);

    return { data, error, isLoading, executeFetch };
};
