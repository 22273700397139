import styled from 'styled-components';

export const PageBody = styled.div({
    display: 'grid',
    gridTemplateColumns: '4fr 5fr',
    width: '90%',
    gap: '4rem',
});

export const PageContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4rem 2rem',
});
