import styled from 'styled-components';

const StyledButton = styled.button({
    border: 'none',
    backgroundColor: '#25C2BC',
    color: '#fff',
    '&:hover': {
        transform: 'scale(1.1)',
    },
});

const StyledText = styled.h4({
    margin: '0.75rem 0',
});

export default function PrimaryButton({ text, props, textProps }) {
    return (
        <StyledButton {...props} type='submit'>
            <StyledText {...textProps}>{text}</StyledText>
        </StyledButton>
    );
}
