import { useEffect, useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { METHODS, WEBHOOK_API_URL } from '../../constants';
import PrimaryTable from '../../design-system/table/customTables/PrimaryTable';
import useFetch from '../../hooks/useFetch';
import { fetchListingOfWebhooks, getWebhooksListingTableData } from './helper';

export default function WebhooksPage() {
    const [ webhooks, setWebhooks] = useState();
    const { executeFetch, error, isLoading } = useFetch(`${WEBHOOK_API_URL}`, {
        method: METHODS.GET,
    });

    useEffect(() => {
        fetchListingOfWebhooks(executeFetch, setWebhooks);
    }, []);

    return (
        <div style={{ paddingBottom: '2rem' }}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div style={{ marginTop: '2rem', marginLeft: '5rem' }}>
                    <div style={{ marginLeft: '1rem' }}>
                        Create a <a href="/webhook/new">new webhook</a>.
                    </div>
                    <div>
                        <PrimaryTable
                            props={{ 'data-testid': 'webhooks-table', style: { marginTop: '1rem', width: '95%' } }}
                            data={getWebhooksListingTableData(webhooks, error)}
                        />
                    </div>
                </div>
            )}
            {error && (
                <div data-testid="webhooks-table-error-message" style={{ padding: '2rem', textAlign: 'center' }}>
                    Error has occurred while fetching webhook definitions. Please try again.
                </div>
            )}
        </div>
    );
}
