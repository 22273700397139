import { createContext, useContext, useEffect, useState } from 'react';
import { METHODS, ORGANIZATION_API_URL } from '../../constants';
import { parseOrganization } from '../../pages/organization/helper';
import useFetch from '../useFetch';

const OrganizationContext = createContext();

export function OrganizationProvider({ children, testValues = null }) {
    const [organization, setOrganization] = useState(false);
    const orgId = window.location.pathname.split('/')[2];
    const [isOrgPage, setIsOrgPage] = useState(false);

    const { executeFetch, error, isLoading } = useFetch(ORGANIZATION_API_URL(orgId), {
        method: METHODS.GET,
    });

    useEffect(() => {
        sessionStorage.setItem('organization', JSON.stringify(organization));
    }, [organization]);

    useEffect(() => {
        if (orgId === undefined) return;
        if (window.location.href?.includes('organization')) {
            setIsOrgPage(true);
        }
        if (isOrgPage) {
            const org = fetchOrganization(executeFetch, setOrganization);
            console.log('organization: ', org);
        }
    }, [orgId, isOrgPage]);

    return (
        <OrganizationContext.Provider
            value={testValues ?? {
                error,
                organization,
                isLoading,
                setOrganization,
            }}>
            {children}
        </OrganizationContext.Provider>
    );
}

export const useOrganization = () => useContext(OrganizationContext);

export const fetchOrganization = async (executeFetch, setOrganization) => {
    await executeFetch().then((response) => {
        console.log('fetch org response:', response);
        if (response.success) {
            const parsedOrg = parseOrganization(response.body);
            console.log('parsed org:', parsedOrg);
            setOrganization(parsedOrg);
        }
    });
};