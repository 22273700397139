import PrimaryButton from "../../design-system/button/customButtons/PrimaryButton";
import { parseDate } from "../organization/helper";

export const fetchPaymentOrders = async (executeFetch, setPaymentOrders) => {
    await executeFetch().then((response) => {
        console.log('fetch response', response);
        if (response.success) {
            setPaymentOrders(response.body.paymentOrders);
        }
    });
};

export const handleClick = (id) => {
    window.open(`https://app.moderntreasury.com/payment_orders/${id}`, "_blank", "noreferrer")
}

export const getPaymentsTableData = (paymentOrders) => {
    return {
        columns: ['Payment Date', 'Counterparty', 'Amount', 'Type', 'Invoice ID', 'Payment Order ID', 'Status', 'Action'],
        rows: paymentOrders?.map((order) => (
            <tr key={order.id}>
                <td>{parseDate(order.created_at)}</td>
                <td>{order.receiving_account.party_name}</td>
                <td>${(order.amount / 100).toFixed(2)}</td>
                <td>{order.direction}</td>
                <td className="scroll">{order?.metadata?.invoice}</td>
                <td className="scroll">{order.id}</td>
                <td>{order.status}</td>
                <td><PrimaryButton text='Approve / Deny' props={{ 'data-testid': 'mt-approve-deny-button', style: { padding: 0 }, onClick: () => handleClick(order.id) }} /></td>
            </tr>
        )),
    };
};
