export const fetchInvoice = async (executeFetch, setInvoice) => {
    await executeFetch().then((response) => {
        if (response.success) {
            setInvoice(response.body);
        }
    });
};

export const getInvoiceData = (invoice) => {

    const MAX_WIDTH = 150;

    function getRow(name, value) {
        return (<tr key={name}>
            <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td>{value}</td>
        </tr>);
    }

    function getRowWithLink(name, value, resource) {
        return (<tr key={name}>
            <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td><a href={ `/${resource}/${value}?` } >{value}</a></td>
        </tr>);
    }

    function getRowForLineItems(name, lineItems) {

        const rows = [];
        lineItems.forEach((line, index) => {
            rows.push(<tr>
                <td>{line.rate}</td>
                <td>{line.quantity}</td>
                <td>{line.description}</td>
                <td>{line.amount}</td>
            </tr>);
        });

        const table = (<table>
            <tr>
                <td>Rate</td>
                <td>Quantity</td>
                <td>Description</td>
                <td>Amount</td>
            </tr>
            {rows}
        </table>);

        return (<tr key={name}>
            <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td>{table}</td>
        </tr>);
    }

    function getRowForOrder(name, order) {
        const table = (<table>
            <tr><td>orderId</td><td><a href={ `/order/${order?.orderId}` } >{order?.orderId}</a> </td></tr>
            <tr><td>productId</td><td>{order?.productId}</td></tr>
            <tr><td>loanId</td><td>{order?.loanId}</td></tr>
            <tr><td>debtFacilityId</td><td>{order?.debtFacilityId}</td></tr>
        </table>);

        return (<tr key={name}>
            <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td>{table}</td>
        </tr>);
    }


    const rows = [];
    rows.push(getRow('Id', invoice?.id));
    rows.push(getRow('Invoice Number', invoice?.invoiceNumber));
    rows.push(getRowWithLink('Billed By', invoice?.billedBy, 'organization'));
    rows.push(getRow('Billed To', invoice?.billedTo));
    rows.push(getRow('Billed To Email', invoice?.billedToEmail));
    rows.push(getRow('Billed To Address', invoice?.billedToAddress));
    rows.push(getRow('Created', invoice?.createdAt));
    rows.push(getRow('Issued', invoice?.issueDate));
    rows.push(getRow('Due', invoice?.dueDate));
    rows.push(getRow('Channel Partner', invoice?.channelPartner));
    rows.push(getRow('importId', invoice?.importId));
    rows.push(getRowForLineItems('Line Items', invoice?.lineItems || []));
    rows.push(getRow('Tax Percent', `${invoice?.taxPercent} %`));
    rows.push(getRow('Tax Amount', `${invoice?.taxAmount} cents`));
    rows.push(getRow('Sub Total Amount', `${invoice?.subTotalAmount} cents`));
    rows.push(getRow('Amount', `${invoice?.amount} cents`));

    if (invoice?.order) {
        rows.push(getRowForOrder('Get Paid Now', invoice?.order));
    }

    return {
        columns: [ 'Invoice' ],
        rows: rows,
    }
};
