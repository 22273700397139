export const fetchOrder = async (executeFetch, setOrder) => {
    await executeFetch().then((response) => {
        if (response.success) {
            setOrder(response.body);
        }
    });
};

export const getOrderData = (order) => {

    const MAX_WIDTH = 150;

    function getRow(name, value) {
        return (<tr key={name}>
            <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td>{value}</td>
        </tr>);
    }
    
    function getRowWithLink(name, value, resource) {
        return (<tr key={name}>
            <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td><a href={ `/${resource}/${value}?` } >{value}</a></td>
        </tr>);
    }

    function getRowForOrderFulfillment(name, orderFulfillment) {

        if (!orderFulfillment) {
            return (<tr key={name}>
                <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td>None</td>
            </tr>);
        }

        const rows = [];
        orderFulfillment.forEach((line, index) => {
            rows.push(<tr>
                <td>{line.orderFulfillmentId}</td>
                <td>{line.orderFulfillmentMethod}</td>
                <td>{line.orderFulfillmentStatus}</td>
                <td>{line.createdAt}</td>
                <td>{line.orderId}</td>
                <td>{line.tracker}</td>
            </tr>);
        });
        const table = (<table>
            <tr>
                <td>orderFulfillmentId</td>
                <td>orderFulfillmentMethod</td>
                <td>orderFulfillmentStatus</td>
                <td>createdAt</td>
                <td>orderId</td>
                <td>tracker</td>
            </tr>
            {rows}
        </table>);

        return (<tr key={name}>
            <td style={{ maxWidth: MAX_WIDTH }}>{name}</td><td>{table}</td>
        </tr>);
    }

    const rows = [];
    rows.push(getRow('orderId', order?.orderId));
    rows.push(getRowWithLink('invoiceId', order?.invoiceId, 'invoice'));
    rows.push(getRow('Channel Partner', order?.channelPartnerId));
    rows.push(getRowWithLink('Initiator', order?.initiator, 'organization'));
    rows.push(getRow('productId', order?.productId));
    rows.push(getRow('createdAt', order?.createdAt));
    rows.push(getRowForOrderFulfillment('orderFulfillment', order?.orderFulfillment));

    return {
        columns: [ 'Order' ],
        rows: rows,
    }
};
