import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { METHODS, ORDER_API_URL } from '../../constants';
import PrimaryTable from '../../design-system/table/customTables/PrimaryTable';
import useFetch from '../../hooks/useFetch';
import { fetchOrder, getOrderData } from './helper';

export default function OrderPage() {
    const params = useParams();

    const [ order, setOrder] = useState();
    const { executeFetch, error, isLoading } = useFetch(`${ORDER_API_URL}/${params.orderId}`, {
        method: METHODS.GET,
    });
    
    useEffect(() => {
        fetchOrder(executeFetch, setOrder);
    }, [params]);

    return (
        <div style={{ paddingBottom: '2rem' }}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <PrimaryTable
                    props={{ 'data-testid': 'order-table', style: { margin: '5rem auto', width: '87%' } }}
                    data={getOrderData(order)}
                />
            )}
            {error && (
                <div data-testid="order-table-error-message" style={{ padding: '2rem', textAlign: 'center' }}>
                    Error has occurred while fetching the order. Please try again.
                </div>
            )}
        </div>
    );}
