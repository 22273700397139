import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PrimaryButton from "../../design-system/button/customButtons/PrimaryButton";

const Form = styled.form({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    maxWidth: '400px',
    fontSize: '14px',
    fontFamily: 'Outfit, sans-serif',
});

export default function SearchForm({ type }) {
    const [id, setId] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
        setId(event.target.value);
    };

    const handleSubmit = () => {
        if (!id) return;
        navigate(`${type.path}/${id}`);
    };

    return (
        <div>
            <h1 data-testid='search-title'>{type.title}</h1>
            <h5 data-testid='search-desc'>{type.desc}</h5>
            <Form>
                <input data-testid='search-input' required onChange={handleChange} value={id} placeholder={`${type.title} ID`} />
                <PrimaryButton
                    text="Search"
                    props={{ 'data-testid': 'search-button', onClick: handleSubmit, style: { maxWidth: '100px' } }}
                />
            </Form>
        </div>
    );
}
