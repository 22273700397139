import { useEffect, useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { INCOMING_PAYMENT_API_URL, METHODS, PAYMENT_API_URL, UPDATE_INCOMING_PAYMENT_API_URL } from '../../constants';
import PrimaryTable from '../../design-system/table/customTables/PrimaryTable';
import useFetch from '../../hooks/useFetch';
import { fetchIncomingPayments, getIncomingPaymentsTableData } from './helper';

export default function IncomingPaymentPage() {
    const [incomingPayments, setIncomingPayments] = useState();
    const [incomingPaymentDetailId, setIncomingPaymentDetailId] = useState();
    const [invoiceExternalId, setInvoiceExternalId] = useState();

    const { executeFetch: getIPD, error: getIPDError, isLoading: getIPDLoading } = useFetch(INCOMING_PAYMENT_API_URL, {
        method: METHODS.GET,
    });
    const { executeFetch: updateIPD, error: updateIPDError, isLoading: updateIPDLoading } = useFetch(INCOMING_PAYMENT_API_URL, {
        method: METHODS.POST,
        body: JSON.stringify({
            incomingPaymentDetailId: incomingPaymentDetailId,
            invoiceExternalId: invoiceExternalId,
        })
    });

    useEffect(() => {
        fetchIncomingPayments(getIPD, setIncomingPayments);
    }, []);

    const ipdTableParams = {
        incomingPayments: incomingPayments,
        updateIPD: updateIPD,
        setIncomingPaymentDetailId: setIncomingPaymentDetailId,
        setInvoiceExternalId: setInvoiceExternalId
    };

    return (
        <div style={{ paddingBottom: '2rem' }}>
            {(getIPDLoading || updateIPDLoading) ? (
                <LoadingSpinner />
            ) : (
                <PrimaryTable
                    props={{ 'data-testid': 'incoming-payment-table', style: { margin: '5rem auto', width: '87%' } }}
                    data={getIncomingPaymentsTableData(ipdTableParams)}
                />
            )}
            {(getIPDError || updateIPDError) && (
                <div data-testid="incoming-payments-table-error-message" style={{ padding: '2rem', textAlign: 'center' }}>
                    Error has occurred while fetching incoming payments. Please try again.
                </div>
            )}
        </div>
    );
}
