import AppRouter from './AppRouter';
import CurrentUserProvider from './hooks/context/useCurrentUser';
import { OrganizationProvider } from './hooks/context/useOrganization';
import './styles.css';

export default function App() {
    return (
        <div className='App'>
            <CurrentUserProvider>
                <OrganizationProvider>
                    <AppRouter />
                </OrganizationProvider>
            </CurrentUserProvider>
        </div>
    );
}
