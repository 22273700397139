/**
 * Set auth expiration timer and redirect user to log in page once auth has expired
 */
export const setAuthExpiration = () => {
    const authCheckInterval = 60000; // check every minute if auth has expired
    const authExpirationMinutes = 59; // auth expires after 59 minutes
    const authStartTime = new Date(); // timer starts when user logs in
    function authInterval() {
        const now = new Date();
        const diff = now - authStartTime;
        const diffMins = diff / 1000 / 60;
        if (diffMins > authExpirationMinutes) {
            sessionStorage.removeItem('auth');
            window.location.reload(true);
        }
    }
    setInterval(authInterval, authCheckInterval);
};

/**
 * Set and store tokens for login persist
 * @param {*} body must include data.body response with tokens
 */
export const setAuth = (body) => {
    const auth = {
        accessToken: body.accessToken,
        idToken: body.idToken,
        refreshToken: body.refreshToken,
    };
    sessionStorage.setItem('auth', JSON.stringify(auth));
};
