import styled from 'styled-components';

const Card = styled.button({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    border: '2px #E3E9F0 solid',
    padding: '0.5rem 1rem',
    margin: '0.5rem',
    color: '#25C2BC',
    '&:hover': {
        color: '#25C2BC',
        backgroundColor: 'rgb(140 197 184 / 22%)',
    },
    '&.active': {
        color: '#25C2BC',
        backgroundColor: 'rgb(140 197 184 / 22%)',
    },
});

const CardTitle = styled.h4({
    margin: '0 1rem',
});

const CardTextDesc = styled.p({
    margin: '0 1rem',
});

export default function CardButton({ active, icon, text, textDesc, onClick }) {
    const isActive = active === text ? 'active' : '';
    return (
        <Card onClick={onClick} className={isActive}>
            {icon}
            <div style={{ textAlign: 'left' }}>
                <CardTitle>{text}</CardTitle>
                <CardTextDesc>{textDesc}</CardTextDesc>
            </div>
        </Card>
    );
}
