import { useEffect, useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { METHODS, PAYMENT_API_URL } from '../../constants';
import PrimaryTable from '../../design-system/table/customTables/PrimaryTable';
import useFetch from '../../hooks/useFetch';
import { fetchPaymentOrders, getPaymentsTableData } from './helper';

export default function PaymentPage() {
    const [paymentOrders, setPaymentOrders] = useState();
    const { executeFetch, error, isLoading } = useFetch(`${PAYMENT_API_URL}?status=needs_approval`, {
        method: METHODS.GET,
    });

    useEffect(() => {
        fetchPaymentOrders(executeFetch, setPaymentOrders);
    }, []);

    return (
        <div style={{ paddingBottom: '2rem' }}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <PrimaryTable
                    props={{ 'data-testid': 'payment-orders-table', style: { margin: '5rem auto', width: '87%' } }}
                    data={getPaymentsTableData(paymentOrders, error)}
                />
            )}
            {error && (
                <div data-testid="payments-table-error-message" style={{ padding: '2rem', textAlign: 'center' }}>
                    Error has occured while fetching payment orders. Please try again.
                </div>
            )}
        </div>
    );
}
