import styled from 'styled-components';

export const Table = styled.table({
    borderLeft: '2px #e3e9f0 solid',
    borderRight: '2px #e3e9f0 solid',
    borderTop: '2px #e3e9f0 solid',
    width: '100%', 
    textAlign: 'left',
})

export const TableFooter = styled.td({
    background:'none', 
    padding:'0.5rem 3rem', 
})