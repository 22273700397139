import { useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useOrganization } from '../../hooks/context/useOrganization';
import Page from '../components/Page';
import SearchForm from '../components/SearchForm';
import { getPageElementsForOrg } from './helper';

export default function OrganizationPage({ type, testOrgId }) {
    const [active, setActive] = useState('Information');
    const { error, isLoading, organization } = useOrganization();
    const { orgId } = useParams() ?? '';
    const showElements = organization && (orgId || testOrgId) && !error;
    const pageElementPropsForOrg = {
        active: active,
        organization: organization,
        setActive: setActive,
        showElements: showElements,
    }

    return (
        <Page>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <SearchForm type={type} />{' '}
                    {error && 'There was an error trying to fetch Organization. Please try again.'}
                    {getPageElementsForOrg(pageElementPropsForOrg)}
                </>
            )}
        </Page>
    );
}
