import jwt_decode from "jwt-decode";
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo.png';
import LoadingSpinner from '../../components/LoadingSpinner';
import { AUTHENTICATE_API_URL, METHODS } from '../../constants';
import { useCurrentUser } from '../../hooks/context/useCurrentUser';
import useFetch from '../../hooks/useFetch';
import { setAuth, setAuthExpiration } from './helper';

const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
    width: '100vw',
    height: '100vh',
});

const Card = styled.div({
    textAlign: 'center',
    color: '#3E5953',
    marginTop: '-100px',
    backgroundColor: '#fff',
    borderRadius: '1rem',
    padding: '3rem 3rem',
    boxShadow:
		'0 2.8px 2.2px rgba(0, 0, 0, .034), 0 6.7px 5.3px rgba(0, 0, 0, .038), 0 12.5px 10px rgba(0, 0, 0, .04), 0 22.3px 17.9px rgba(0, 0, 0, .05), 0 41.8px 33.4px rgba(0, 0, 0, .06), -20px 40px 80px rgba(0, 0, 0, .05)',
});

export default function LoginPage({ testIsLoading, testIsError }) {
    const { isLoggedIn, setIsLoggedIn, userEmail, setUserEmail } = useCurrentUser();
    const url = window.location.href;
    const {
        executeFetch: executeGetAuthCode,
        error: getAuthCodeError,
        isLoading: getAuthCodeIsLoading,
    } = useFetch(AUTHENTICATE_API_URL, { method: METHODS.GET });
    const {
        executeFetch: executeGetAuthTokens,
        error: getAuthTokensError,
        isLoading: getAuthTokensIsLoading,
    } = useFetch(AUTHENTICATE_API_URL, { 
        method: METHODS.POST, 
        body: JSON.stringify({url: url})
    });
    const isLoading = getAuthCodeIsLoading || getAuthTokensIsLoading;
    const isError = getAuthCodeError || getAuthTokensError;

    const handleCallbackResponse = useCallback(
        async (res) => {
            await executeGetAuthCode().then((response) => {
                window.location.href = response.body;
            });
            const userObject = jwt_decode(res.credential);
            localStorage.setItem('userEmail', JSON.stringify(userObject.email));
        },
        [executeGetAuthCode]
    );
    
    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT,
            callback: handleCallbackResponse,
        });

        google.accounts.id.renderButton(document.getElementById('googleSignIn'), {
            size: 'large',
        });
    }, [handleCallbackResponse]);

    useEffect(() => {
        const getAuthTokens = async () => {
            await executeGetAuthTokens().then((response) => {
                const body = {
                    accessToken: response.body.tokens.access_token,
                    idToken: response.body.tokens.id_token,
                    refreshToken: response.body.tokens.refresh_token,
                };
                setAuth(body);
                setAuthExpiration();
                setIsLoggedIn(true);
            });
        };

        if (url?.includes('code') && !isLoggedIn) {
            getAuthTokens();
            window.history.pushState('object', document.title, url?.split('?code=')[0]);
        }
    }, [executeGetAuthTokens, isLoggedIn, setIsLoggedIn, url]);

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <Container>
            <Card>
                <img src={logo} alt='lunch_logo' width='100px' />
                <h3 style={{ margin: '1rem 0', fontSize: '16px' }}>Log in to your account</h3>
                <div id='googleSignIn' />
                {isError && (
                    <div style={{ margin: '1rem 0' }}>There was an error while trying to log in.</div>
                )}
            </Card>
        </Container>
    );
}
