import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { METHODS, WEBHOOK_API_URL } from '../../constants';
import PrimaryTable from '../../design-system/table/customTables/PrimaryTable';
import useFetch from '../../hooks/useFetch';
import { fetchWebhook, getWebhookData } from './helper';


export default function WebhookPage() {
    const params = useParams();

    const [ webhook, setWebhook] = useState();
    const { executeFetch, error, isLoading } = useFetch(`${WEBHOOK_API_URL}/${params.webhookId}`, {
        method: METHODS.GET,
    });
    
    useEffect(() => {
        fetchWebhook(executeFetch, setWebhook);
    }, [params]);

    return (
        <div style={{ paddingBottom: '2rem' }}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <PrimaryTable
                    props={{ 'data-testid': 'webhook-table', style: { margin: '5rem auto', width: '87%' } }}
                    data={getWebhookData(webhook)}
                />
            )}
            {error && (
                <div data-testid="webhooks-table-error-message" style={{ padding: '2rem', textAlign: 'center' }}>
                    Error has occurred while fetching the webhook definition. Please try again.
                </div>
            )}
        </div>
    );}
