import PrimaryButton from '../../design-system/button/customButtons/PrimaryButton';

export const fetchIncomingPayments = async (executeFetch, setIncomingPayments) => {
    await executeFetch().then((response) => {
        console.log('Fetch Incoming Payments response: ', response);
        if (response.success) {
            setIncomingPayments(response.body);
        }
    });
};

export const getIncomingPaymentsTableData = (ipdTableParams) => {
    return {
        columns: ['Incoming Payment Detail ID', 'Status', 'Amount (in cents)', 'Metadata form', 'Action'],
        rows: ipdTableParams.incomingPayments?.map((incomingPayment) => (
            <tr key={incomingPayment.incomingPaymentId}>
                <td>{incomingPayment.incomingPaymentId}</td>
                <td>{incomingPayment.resolutionStatus}</td>
                <td>{incomingPayment.details[0].amount}</td>
                <td>
                    <input style={{ width: '280px' }} placeholder={'invoice id'} onChange={(e) => {
                        ipdTableParams.setInvoiceExternalId(e.target.value);
                        ipdTableParams.setIncomingPaymentDetailId(incomingPayment.incomingPaymentId)
                    }} />
                </td>
                <td>
                    <PrimaryButton text='Submit Metadata' props={{ style: { padding: 0, width: '160px' }, onClick: () => ipdTableParams.updateIPD() }} textProps={{ style: {textAlignVertical: 'center', textAlign: 'center'} }}/>
                </td>
            </tr>
        )),
    };
};
