import { Table, TableFooter } from '../Table';

export default function PrimaryTable({ data, props }) {
    return (
        <Table {...props}>
            <thead>
                <tr>
                    {data.columns.map((column) => (
                        <th key={column}>{column}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.rows}
                {data.footer && 
                <tr>
                    {/* pagination will be added here */}
                    <TableFooter>{data.footer}</TableFooter> 
                </tr>}
            </tbody>
        </Table>
    )
}