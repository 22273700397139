export const fetchWebhook = async (executeFetch, setWebhook) => {
    await executeFetch().then((response) => {
        if (response.success) {
            setWebhook(response.body);
        }
    });
};

export const fetchListingOfWebhooks = async (executeFetch, setWebhooks) => {
    await executeFetch().then((response) => {
        if (response.success) {
            setWebhooks(response.body.definitions);
        }
    });
};

export const stackedEvents = (events) => {
    let stackedEvents = [];
    events?.forEach((event, index) => {
        stackedEvents.push(<div key={index}>{event}</div>);
    });
    return stackedEvents;
}

export const getWebhookData = (definition) => {

    function getRow(name, value) {
        return (<tr key={name}>
            <td style={{ maxWidth: 150 }}>{name}</td><td>{value}</td>
        </tr>);
    }

    const rows = [];
    rows.push(getRow('Id', definition?.id));
    rows.push(getRow('Type', definition?.notificationType));
    rows.push(getRow('Endpoint', definition?.endpoint));
    rows.push(getRow('Description', definition?.description));
    rows.push(getRow('Events', stackedEvents(definition?.events)));
    rows.push(getRow('Created For', definition?.createdFor));
    rows.push(getRow('Created By', definition?.createdBy));
    rows.push(getRow('Created At', definition?.createdAt));
    rows.push(getRow('Updated At', definition?.updatedAt));
    rows.push(getRow('Version', definition?.version));

    return {
        columns: [ 'Webhook' ],
        rows: rows,
    }
};
export const getWebhooksListingTableData = (definitions) => {
    return {
        columns: [ 'Id', 'Events', 'Endpoint', 'Created By', 'Created For', 'Description' ],
        rows: definitions?.map((definition) => (
            <tr key={definition?.id}>
                <td><a href={`/webhook/${definition?.id}`}>{(definition.id).substring(0,8)}</a></td>
                <td className="scroll">{stackedEvents(definition?.events)}</td>
                <td className="scroll">{definition?.endpoint}</td>
                <td>{definition?.createdBy}</td>
                <td>{definition?.createdFor}</td>
                <td>{definition?.description}</td>
            </tr>
        )),
    }
};