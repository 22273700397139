import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner';
import NavigationBar from './components/NavigationBar';
import { useCurrentUser } from './hooks/context/useCurrentUser';
import InvoicePage from './pages/invoice/InvoicePage';
import LoginPage from './pages/login/LoginPage';
import OrderPage from './pages/order/OrderPage';
import OrganizationPage from './pages/organization/OrganizationPage';
import PaymentPage from './pages/payment/PaymentPage';
import PurchaseOrderPage from './pages/purchaseorder/PurchaseOrderPage';
import WebhookNewPage from './pages/webhook/WebhookNewPage';
import WebhookPage from './pages/webhook/WebhookPage';
import WebhooksPage from './pages/webhook/WebhooksPage';
import routes from './utils';
import IncomingPaymentPage from './pages/incoming-payment/IncomingPaymentPage';

export default function AppRouter() {
    const { isLoading, isLoggedIn } = useCurrentUser();
    return (
        <BrowserRouter>
            {isLoggedIn ? (
                <Suspense fallback={<LoadingSpinner />}>
                    <NavigationBar />
                    <Routes>
                        <Route path='/' element={<OrganizationPage type={routes.ORGANIZATION} />} />
                        <Route
                            path={routes.ORGANIZATION.path}
                            element={<OrganizationPage type={routes.ORGANIZATION} />}
                        />
                        <Route
                            path={`${routes.ORGANIZATION.path}/:orgId`}
                            element={<OrganizationPage type={routes.ORGANIZATION} />}
                        />
                        <Route path={routes.PAYMENT.path} element={<PaymentPage type={routes.PAYMENT} />} />
                        <Route path={routes.INCOMING_PAYMENT.path} element={<IncomingPaymentPage type={routes.INCOMING_PAYMENT} />} />
                        <Route path={`${routes.INVOICE.path}/:invoiceId`} element={<InvoicePage type={routes.INVOICE} />} />
                        <Route path={`${routes.PURCHASE_ORDER.path}/:purchaseorderId`} element={<PurchaseOrderPage type={routes.PURCHASE_ORDER} />} />
                        <Route path={`${routes.ORDER.path}/:orderId`} element={<OrderPage type={routes.ORDER} />} />
                        <Route
                            path={routes.WEBHOOKS.path}
                            element={<WebhooksPage type={routes.WEBHOOKS} />}
                        />
                        <Route
                            path={routes.WEBHOOK_NEW.path}
                            element={<WebhookNewPage type={routes.WEBHOOK_NEW} />}
                        />
                        <Route path={`${routes.WEBHOOK.path}/:webhookId`} element={<WebhookPage type={routes.WEBHOOK} />} />
                        <Route path={routes.LOGIN.path} element={<LoginPage type={routes.LOGIN} />} />
                    </Routes>
                </Suspense>
            ) : !isLoading && !isLoggedIn ? (
                <LoginPage />
            ) : (
                <LoadingSpinner />
            )}
        </BrowserRouter>
    );
}
