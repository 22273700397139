import styled from 'styled-components';

const DataTable = styled.table({
    width: '730px',
    background: '#fff',
    border: '2px #E3E9F0 solid',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0',
    color: '#3E5953',
});

const DataRow = styled.tr({
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: 'none!important',
});

export const DataHeadCell = styled.td({
    fontWeight: 'bold',
    borderRight: '2px #E3E9F0 solid',
    overflow: 'hidden',
    width: '120px',
});

export const DataCell = styled.td({
    overflow: 'hidden',
    padding: '0 1.5rem 1rem',
});

export default function DataContainer({ props }) {
    return (
        <DataTable {...props}>
            <tbody>
                {Object.values(props?.data).map((item) => (
                    <DataRow key={`${item.title}-${item.value}`}>
                        <DataHeadCell>{item?.title}</DataHeadCell>
                        <DataCell>{item?.value}</DataCell>
                    </DataRow>
                ))}
            </tbody>
        </DataTable>
    );
}
