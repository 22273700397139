// api urls
export const AUTHENTICATE_API_URL = `${process.env.REACT_APP_OPS_CONTROLLER}/oc/authenticate`;
export const ORGANIZATION_API_URL = (id) =>
    `${process.env.REACT_APP_OPS_CONTROLLER}/oc/organization?id=${id}`;
export const PAYMENT_API_URL = `${process.env.REACT_APP_OPS_CONTROLLER}/oc/payment`;
export const INCOMING_PAYMENT_API_URL = `${process.env.REACT_APP_OPS_CONTROLLER}/oc/incoming-payment`;
export const WEBHOOK_API_URL = `${process.env.REACT_APP_OPS_CONTROLLER}/oc/webhook`;
export const INVOICE_API_URL = `${process.env.REACT_APP_OPS_CONTROLLER}/oc/invoice`;
export const ORDER_API_URL = `${process.env.REACT_APP_OPS_CONTROLLER}/oc/order`;
export const PURCHASE_ORDER_API_URL = `${process.env.REACT_APP_OPS_CONTROLLER}/oc/purchaseorder`;

// methods
export const METHODS = { GET: 'GET', POST: 'POST' };

// headers
export const APPLICATION_JSON = 'application/json';