const routes = {
    ORGANIZATION: {
        title: 'Organization',
        path: '/organization',
        desc: 'Search an organization by entering the organization ID.',
        navbar: true,
    },
    PAYMENT: {
        title: 'Payment',
        path: '/payment',
        desc: 'Search a payment by entering the payment ID.',
        navbar: true,
    },
    INCOMING_PAYMENT: {
        title: 'Incoming Payment',
        path: '/incoming-payment',
        desc: 'List all failed incoming payments and update metadata.',
        navbar: true,
    },
    INVOICE: {
        title: 'Invoice',
        path: '/invoice',
        desc: 'View invoice.',
    },
    ORDER: {
        title: 'Order',
        path: '/order',
        desc: 'View order.',
    },
    PURCHASE_ORDER: {
        title: 'Purchase Order',
        path: '/purchaseorder',
        desc: 'View order.',
    },
    WEBHOOKS: {
        title: 'Webhooks',
        path: '/webhooks',
        desc: 'Review existing webhook integrations and create new ones.',
        navbar: true,
    },
    WEBHOOK: {
        title: 'Webhook',
        path: '/webhook',
        desc: 'View webhook.',
    },
    WEBHOOK_NEW: {
        title: 'Create New Webhook Endpoint',
        path: '/webhook/new',
        desc: 'Create a new webhook endpoint.',
    },
    LOGIN: {
        title: 'Login',
        path: '/login',
        desc: 'Login in the Ops Tool.',
    },
};

export default routes;